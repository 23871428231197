import {
    createWebHistory,
    createRouter
} from "vue-router";
import Login from "@/Views/Onbording/login.vue";
import RePayment from "@/Views/Onbording/Repayment.vue";
import ForgetPassword from "@/Views/Onbording/forgetPassword.vue";
import ResetPassword from "@/Views/Onbording/resetPassword.vue";
import MainView from "@/Views/main.vue";
import DashBoard from "@/Views/Dashboard/dashboard.vue";
import BranchInfo from "@/Views/Dashboard/branchInfo.vue";
import Profile from "@/Views/Profile/index.vue";
import WelcomePage from "@/Views/Profile/welcomePage.vue";
import LoanType from "@/Views/Loantype/loanType.vue";
import AddLoanType from "@/Views/Loantype/addLoanType.vue";
import EmiPaymentVerification from "@/Views/EmiPayment/index.vue";
import ViewEmiPaymentVerification from "@/Views/EmiPayment/detail.vue";
import LoanApplication from "@/Views/LoanApplication/loanApplication.vue";
import AddGuarantor from "@/Views/LoanApplication/addEditGuarantor1.vue";
import AddLoanApplication from "@/Views/LoanApplication/addLoanApplication.vue";
import ViewLoanApplication from "@/Views/LoanApplication/viewLoanApplication.vue";
import ViewVerifiedLoanApplication from "@/Views/LoanApplication/viewApplication.vue";
import ViewApplication from "@/Views/LoanApplication/viewApplication.vue";
import LoanSettlement from "@/Views/LoanSettlement/index.vue";
import ViewLoanSettlement from "@/Views/LoanSettlement/detail.vue";
import LoanWaiveOff from "@/Views/LoanWaiveOff/index.vue";
// import ViewLoanWaiveOff from "@/Views/LoanWaiveOff/detail.vue";
import LoanDisbursed from "@/Views/LoanDisbursed/index.vue";
import ViewLoanDisbursed from "@/Views/LoanDisbursed/detail.vue";
import RenewLoan from "@/Views/RenewLoan/index.vue";
import ViewRenewLoan from "@/Views/RenewLoan/detail.vue";
import AddPayment from "@/Views/LoanDisbursed/add-payment.vue";
import UserManagement from "@/Views/UserManagement/index.vue";
import AddUser from "@/Views/UserManagement/addEdit.vue";
import EditUser from "@/Views/UserManagement/addEdit.vue";
import LedgerUserManagement from "@/Views/LedgerManagement/index.vue";
import LedgerAddUser from "@/Views/LedgerManagement/addEdit.vue";
import LedgerEditUser from "@/Views/LedgerManagement/addEdit.vue";
import BussinessOffer from "@/Views/BussinessOffer/index.vue";
import viewOfferDetails from "@/Views/BussinessOffer/viewOfferDetails.vue";
import LeadManagement from "@/Views/LeadManagement/lead.vue";
import AddLeadManagement from "@/Views/LeadManagement/addLead.vue";
import RoleManagement from "@/Views/RoleManagement/index.vue";
import AddRole from "@/Views/RoleManagement/addEdit.vue";
import EditRole from "@/Views/RoleManagement/addEdit.vue";
import ExpensManagement from "@/Views/ExpensManagement/index.vue";
import AddExpense from "@/Views/ExpensManagement/add-expense.vue";
import ViewEmployeeExpense from "@/Views/ExpensManagement/view-employee-expense.vue";
import Office from "@/Views/ExpensManagement/office.vue";
import EmployeeExpenseVerification from "@/Views/ExpensManagement/employee.vue";
import EmployeeExpenses from "@/Views/ExpensManagement/employeeAccount.vue";
import Ledger from "@/Views/ExpensManagement/ledger.vue";
import EmployeeAccountDetails from "@/Views/ExpensManagement/employeeAccountDetails.vue";
import LedgerDetails from "@/Views/ExpensManagement/ledgerDetails.vue";

import ManageEditBranch from "@/Views/ManageBranch/editBranch.vue";
import ExpenseCategory from "@/Views/ExpenseCategory/expenseCategory.vue";
import AddExpenseCategory from "@/Views/ExpenseCategory/addExpenseCategory.vue";
import CustomerManagement from "@/Views/CustomerManagement/customerManagement.vue";
import CustomerDetail from "@/Views/CustomerManagement/customerDetail.vue";
import AssetManagement from "@/Views/AssetManagement/assetManagement.vue";
import AddAssetManagement from "@/Views/AssetManagement/addAssetManagement.vue";
import AssetAlerts from "@/Views/AssetManagement/assetAlert.vue";
import ManageBranch from "@/Views/ManageBranch/branch.vue";
import AddBranch from "@/Views/ManageBranch/addBranch.vue";
import BroadCast from "@/Views/BroadCast/index.vue";
import AddBroadCast from "@/Views/BroadCast/add-broadcast.vue";
import History from "@/Views/BroadCast/history.vue";
import Incentive from "@/Views/Incentive/index.vue";
import AddIncentive from "@/Views/Incentive/add-incentive.vue";
import CollectionPlan from "@/Views/CollectionPlan/index.vue";
import VerificationPlan from "@/Views/VerificationPlan/index.vue";
import Commission from "@/Views/Commission/index.vue";
import AddCommission from "@/Views/Commission/addCommision.vue";
import Interests from "@/Views/Interests/index.vue";
import AddInterest from "@/Views/Interests/addInterest.vue";
import Reference from "@/Views/Reference/index.vue";
import AddReference from "@/Views/Reference/addEditReference.vue";
import EditReference from "@/Views/Reference/addEditReference.vue";
import Earning from "@/Views/GetEarning/index.vue";
import FeeReport from "@/Views/FeeReport/index.vue";
import TdsReport from "@/Views/TdsReport/index.vue";
import GstReport from "@/Views/GstReport/index.vue";
import Setting from "@/Views/Settings/setting.vue";

const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/repay",
        name: "Repay",
        component: RePayment,
    },
    {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
    },
    {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "",
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "/profile",
                name: "ProfileSection",
                component: Profile,
            },
            {
                path: "/welcome-page",
                name: "WelcomePage",
                component: WelcomePage,
            },
            {
                path: "/dashboard",
                name: "DashBoard",
                component: DashBoard,
            },
            {
                path: "/traget-info/:branch_id",
                name: "BranchTragetInfo",
                component: BranchInfo,
            },
            {
                path: "/loan_type",
                name: "LoanType",
                component: LoanType,
            },
            {
                path: "/add-loan-type",
                name: "AddLoanType",
                component: AddLoanType,
            },
            {
                path: "/edit-loan-type/:id",
                name: "EditLoanType",
                component: AddLoanType,
            },
            {
                path: "/emi_payment_verification",
                name: "EmiPaymentVerification",
                component: EmiPaymentVerification,
            },
            {
                path: "/view-emi_payment/:id/:loan_id/:access?",
                name: "ViewEmiPaymentVerification",
                component: ViewEmiPaymentVerification,
            },
            {
                path: "/loan_applications/:branch_id?",
                name: "LoanApplication",
                component: LoanApplication,
            },
            {
                path: "/add-loan-applications",
                name: "AddLoanApplication",
                component: AddLoanApplication,
            },
            {
                path: "/add-loan-application/:lead_id",
                name: "AddLoanApplicationLead",
                component: AddLoanApplication,
            },
            {
                path: "/view-application",
                name: "ViewApplication",
                component: ViewApplication,
            },
            {
                path: "/edit-loan-applications/:id",
                name: "EditLoanApplication",
                component: AddLoanApplication,
            },
            {
                path: "/view-loan-application/:id/:access?",
                name: "ViewLoanApplication",
                component: ViewLoanApplication,
            },
            {
                path: "/add-guarantor/:id?",
                name: "AddGuarantor",
                component: AddGuarantor,
            },
            {
                path: "/edit-guarantor/:id/:guarantor_uuid",
                name: "AddEditGuarantor",
                component: AddGuarantor,
            },
            {
                path: "/view-verified-loan-application/:id",
                name: "ViewVerifiedLoanApplication",
                component: ViewVerifiedLoanApplication,
            },
            {
                path: "/loan_settlement_requests",
                name: "LoanSettelment",
                component: LoanSettlement,
            },
            {
                path: "/view-settlement/:id/:loan_id",
                name: "ViewLoanSettelment",
                component: ViewLoanSettlement,
            },
            {
                path: "/loan-waive-off",
                name: "LoanWaiveOff",
                component: LoanWaiveOff,
            },
            // {
            //     path: "/view-waive-off/:id/:loan_id",
            //     name: "ViewLoanWaiveOff",
            //     component: ViewLoanWaiveOff,
            // },
            {
                path: "/loan_disbursed",
                name: "LoanDisbursed",
                component: LoanDisbursed,
            },
            {
                path: "/view-disbursed/:id/:access?",
                name: "ViewLoanDisbursed",
                component: ViewLoanDisbursed,
            },
            {
                path: "/add-payment/:id",
                name: "AddPayment",
                component: AddPayment,
            },
            {
                path: "/renew_loan",
                name: "RenewLoan",
                component: RenewLoan,
            },
            {
                path: "/view-renew/:id/:access?",
                name: "ViewRenewLoan",
                component: ViewRenewLoan,
            },
            {
                path: "/user_management",
                name: "UserManagement",
                component: UserManagement,
            },
            {
                path: "/add-user",
                name: "AddUser",
                component: AddUser,
            },
            {
                path: "/edit-user/:id",
                name: "EditUser",
                component: EditUser,
            },
            {
                path: "/ledger_user",
                name: "LedgerUserManagement",
                component: LedgerUserManagement,
            },
            {
                path: "/add-ledger-user",
                name: "LedgerAddUser",
                component: LedgerAddUser,
            },
            {
                path: "/edit-ledger-user/:id",
                name: "LedgerEditUser",
                component: LedgerEditUser,
            },
            {
                path: "/business_offer",
                name: "BussinessOffer",
                component: BussinessOffer,
            },
            {
                path: "/view-business_offer/:id/:access?",
                name: "viewOfferDetails",
                component: viewOfferDetails,
            },
            {
                path: "/lead_management",
                name: "LeadManagement",
                component: LeadManagement,
            },
            {
                path: "/add-lead-management",
                name: "AddLeadManagement",
                component: AddLeadManagement,
            },
            {
                path: "/edit-lead-management/:id",
                name: "EditLeadManagement",
                component: AddLeadManagement,
            },
            {
                path: "/role_management",
                name: "RoleManagement",
                component: RoleManagement,
            },
            {
                path: "/add-role",
                name: "AddEditRole",
                component: AddRole,
            },
            {
                path: "/edit-role/:id",
                name: "EditRole",
                component: EditRole,
            },
            {
                path: "/view-role/:id/:view?",
                name: "ViewRole",
                component: EditRole,
            },
            {
                path: "/expense_management",
                name: "ExpensManagement",
                component: ExpensManagement,
            },
            {
                path: "/add-expense",
                name: "AddExpense",
                component: AddExpense,
            },
            {
                path: "/collection_plan",
                name: "CollectionPlan",
                component: CollectionPlan,
            }, {
                path: "/verification_plan",
                name: "VerificationPlan",
                component: VerificationPlan,
            },
            {
                path: "/office-expenses",
                name: "Office",
                component: Office,
            },
            {
                path: "/employee-expense-verification",
                name: "EmployeeExpenseVerification",
                component: EmployeeExpenseVerification,
            },
            {
                path: "/ledger",
                name: "Ledger",
                component: Ledger,
            },
            {
                path: "/view-employee-expense/:id/:access?",
                name: "ViewEmployeeExpense",
                component: ViewEmployeeExpense,
            },
            {
                path: "/employee-expenses",
                name: "EmployeeExpenses",
                component: EmployeeExpenses,
            },
            {
                path: "/employee-account/:uuid/:access?",
                name: "EmployeAccountDetails",
                component: EmployeeAccountDetails,
            },
            {
                path: "/ledger/:uuid/:access?",
                name: "LedgerDetails",
                component: LedgerDetails,
            },
            

            {
                path: "/expense_category",
                name: "ExpenseCategory",
                component: ExpenseCategory,
            },
            {
                path: "/add-expense-category",
                name: "AddExpenseCategory",
                component: AddExpenseCategory,
            },
            {
                path: "/edit-expense-category/:id",
                name: "EditExpenseCategory",
                component: AddExpenseCategory,
            },
            {
                path: "/customer_management",
                name: "CustomerManagement",
                component: CustomerManagement,
            },
            {
                path: "/customer-detail/:id/:access?",
                name: "CustomerDetail",
                component: CustomerDetail,
            },
            {
                path: "/asset_management",
                name: "AssetManagement",
                component: AssetManagement,
            },
            {
                path: "/asset-alert",
                name: "AssetAlert",
                component: AssetAlerts,
            },
            {
                path: "/add-asset-management",
                name: "AddAssetManagement",
                component: AddAssetManagement,
            },
            {
                path: "/edit-asset-management/:id",
                name: "editAssetManagement",
                component: AddAssetManagement,
            },
            {
                path: "/manage_branch",
                name: "ManageBranch",
                component: ManageBranch,
            },
            {
                path: "/edit_branch/:uuid/:access?",
                name: "ManageEditBranch",
                component: ManageEditBranch,
            },
            {
                path: "/add-manage-branch",
                name: "AddBranch",
                component: AddBranch,
            },
            {
                path: "/edit-manage-branch/:id",
                name: "EditBranch",
                component: AddBranch,
            },
            {
                path: "/broadcast",
                name: "BroadCast",
                component: BroadCast,
            },
            {
                path: "/add-broadcast",
                name: "AddBroadCast",
                component: AddBroadCast,
            },
            {
                path: "/history/:id",
                name: "History",
                component: History,
            },
            {
                path: "/incentive",
                name: "Incentive",
                component: Incentive,
            },
            {
                path: "/commissions",
                name: "Commission",
                component: Commission,
            },
            {
                path: "/add-commission",
                name: "AddCommission",
                component: AddCommission,
            },
            {
                path: "/interests",
                name: "Interests",
                component: Interests,
            },
            {
                path: "/add-interest",
                name: "AddInterest",
                component: AddInterest,
            },
            {
                path: "/add-incentive",
                name: "AddIncentive",
                component: AddIncentive,
            },
            {
                path: "/edit-incentive/:id",
                name: "EditIncentive",
                component: AddIncentive,
            },
            {
                path: "/reference",
                name: "Reference",
                component: Reference,
            },
            {
                path: "/add-reference",
                name: "AddReference",
                component: AddReference,
            },
            {
                path: "/edit-reference/:id",
                name: "EditReference",
                component: EditReference,
            },
            {
                path: "/get-earnings",
                name: "GetEarning",
                component: Earning,
            },
            {
                path: "/tds-report",
                name: "TdsEarning",
                component: TdsReport,
            },
            {
                path: "/gst-report",
                name: "GstReport",
                component: GstReport,
            },
            {
                path: "/processing-fee-report",
                name: "FeeReport",
                component: FeeReport,
            },
            {
                path: "/settings",
                name: "ManageSetting",
                component: Setting,
            },

        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("access_token") == null) {
            next({
                path: "/login",
                params: {
                    nextUrl: to.fullPath
                },
            });
        } else {
            next();
        }
    } else {
        if (to.path === " ") {
            if (localStorage.getItem("access_token") != null) {
                next({
                    path: "/",
                    params: {
                        nextUrl: to.fullPath
                    },
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router;