<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-md-4 pr-2">
              <h2 class="content-title">
                <a @click="$router.go(-1)" style="cursor: pointer"
                  ><i class="fas fa-angle-left mr-2"></i
                  ><span> View Branch</span>
                </a>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
                <li class="nav-item">
                  <a
                    id="tab-1"
                    @click="changeTab('SUMMARY')"
                    data-toggle="tab"
                    href="#tab_1"
                    role="tab"
                    aria-controls="tab_1"
                    aria-selected="true"
                    class="nav-link active"
                    >Summary</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-2"
                    @click="changeTab('FUNDS')"
                    data-toggle="tab"
                    href="#tab_2"
                    role="tab"
                    aria-controls="tab_2"
                    aria-selected="false"
                    class="nav-link"
                    >Funds</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-3"
                    @click="changeTab('TARGET')"
                    data-toggle="tab"
                    href="#tab_3"
                    role="tab"
                    aria-controls="tab_3"
                    aria-selected="false"
                    class="nav-link"
                    >Target</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-4"
                    @click="changeTab('STATEMENT')"
                    data-toggle="tab"
                    href="#tab_4"
                    role="tab"
                    aria-controls="tab_4"
                    aria-selected="false"
                    class="nav-link"
                    >Statement</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-5"
                    @click="changeTab('PASSBOOK')"
                    data-toggle="tab"
                    href="#tab_5"
                    role="tab"
                    aria-controls="tab_5"
                    aria-selected="false"
                    class="nav-link"
                    >Passbook</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-lg-12">
              <div id="myTabContent" class="tab-content">
                <div
                  id="tab_1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  class="tab-pane fade active show"
                >
                  <div class="row">
                    <div class="col-lg-6 pt-3 mx-auto">
                      <div class="card rounded-2" style="border-radius: 10px">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-3">
                              <h5 class="label-text">Branch Name</h5>
                            </div>
                            <div class="col-lg-9">
                              <h5 class="text-bolder">
                                : {{ userSummary?.name }}
                              </h5>
                            </div>
                            <div class="col-lg-3">
                              <h5 class="label-text">City</h5>
                            </div>
                            <div class="col-lg-9">
                              <h5 class="text-bolder">
                                : {{ userSummary?.city }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 pt-3 mx-auto">
                      <div class="card rounded-2" style="border-radius: 10px">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-3">
                              <h5 class="label-text">Total Fund</h5>
                            </div>
                            <div class="col-lg-9">
                              <h5 class="text-bolder">
                                :
                                <span>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      userSummary?.total_available_fund
                                    )
                                  }}
                                </span>
                              </h5>
                            </div>
                            <div class="col-lg-8 mx-auto pt-5 pb-3">
                              <div
                                class="d-flex flex-row justify-content-center"
                              >
                                <a
                                  class="btn mx-1 green-box"
                                  v-if="access.can_update == 1"
                                  href="javascript:void(0)"
                                  style="cursor: pointer !important"
                                  @click="onMoneyInOut('MONEY_IN')"
                                  ><span>Payment Receive</span></a
                                >
                                <a
                                  v-if="access.can_update == 1"
                                  href="javascript:void(0)"
                                  style="cursor: pointer !important"
                                  class="btn mx-1 red-box"
                                  @click="onMoneyInOut('MONEY_OUT')"
                                  ><span>Payout</span></a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="tab_2"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">

                        <div class="row pb-2">
                          <div class="col-lg-3 mb-2">
                            <div class="search-form position-relative">
                              <input
                                type="text"
                                class="form-control"
                                v-model="filterObj.keyword"
                                placeholder="Search"
                              />
                              <div class="input-group-prepend position-absolute">
                                <button class="" type="button" id="button-addon1">
                                  <img src="/static/img/search.svg" width="15" />
                                </button>
                              </div>
                            </div>
                            </div>

                          <div class="col-lg-2 mb-2">
                            <VueDatePicker
                              placeholder="Select Date Range"
                              :format="'dd/MM/yyyy'"
                              style="height: 46px !important"
                              v-model="fund_statement_date"
                              range
                              :type="boolean"
                              :default="true"
                              :enable-time-picker="false"
                            >
                            </VueDatePicker>
                          </div>
                          <div class="col-lg-2 mb-2">
                            <VueDatePicker
                              placeholder="Select Payment Date Range"
                              :format="'dd/MM/yyyy'"
                              style="height: 46px !important"
                              v-model="fund_payment_date"
                              range
                              :type="boolean"
                              :default="true"
                              :enable-time-picker="false"
                            >
                            </VueDatePicker>
                          </div>
                          <div class="col-lg-3 mb-2">
                            <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                              >Search
                            </a>
                            <a @click="reset()" class="btn btn-brand-03 mr-2"
                              >Reset
                            </a>
                          </div>
                          <div class="col-lg-2 mb-2 text-right">
                            <a v-if="access.can_create == 1"
                            class="btn btn-brand-02"
                            @click="onMoneyInOut('FUNDS')"
                          >
                            + Add Fund</a
                          >
                          </div>
                        </div>

                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Payment Date</th>
                              <th scope="col">Particulars</th>
                              <th scope="col">Partner</th>
                              <th scope="col">Added By</th>

                              <th scope="col" class="text-center">Amount</th>
                              <th scope="col" class="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="7" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td>
                                {{
                                  $helperService.getFormattedDate(
                                    item.created_at
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  $helperService.getFormattedDateOnly(
                                    item.added_date
                                  )
                                }}
                              </td>
                              <td>{{ item?.narration }}</td>
                              <td>{{ item?.funded_by_user?.name }}</td>
                              <td>{{ item?.added_by_user?.name }}</td>
                              <td
                                v-if="item.type == 'DEBIT'"
                                class="red-text text-right"
                              >
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.amount
                                  )
                                }}
                              </td>
                              <td
                                v-if="item.type == 'CREDIT'"
                                class="green-text text-right"
                              >
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.amount
                                  )
                                }}
                              </td>
                              <td>
                                <a
                                  title="View"
                                  v-if="hasFullAccess"
                                  class="mx-2"
                                  style="cursor: pointer"
                                  @click="onDeleteFundEvent(item)"
                                  ><img
                                    src="/static/img/delete-icon.svg"
                                    alt=""
                                /></a>
                              </td>
                              <!-- <td>
                                                                                          <a title="View" class="mx-2" style="cursor: pointer"
                                                                                              @click="view(item)"><img src="/static/img/eye-icon.svg"
                                                                                                  alt="" /></a>
                                                                                      </td> -->
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="changeTab(active_tab)"
                          ref="FUNDS_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="FUNDS_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tab 3 -->
                <div
                  id="tab_3"
                  role="tabpanel"
                  aria-labelledby="tab-3"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row pb-2">
                        <div
                          class="col-lg-12 text-right"
                          v-if="access.can_create == 1"
                        >
                          <a
                            class="btn btn-brand-02"
                            @click="onShowTargetModal(target_uuid)"
                          >
                            + Add Target</a
                          >
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-primary mg-b-0">
                          <thead>
                            <tr>
                              <th scope="col">Year</th>
                              <th scope="col">Quarter</th>

                              <th scope="col">Target</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loader">
                              <td colspan="4" class="text-center">
                                <div
                                  class="spinner-border spinner-color"
                                  role="status"
                                ></div>
                              </td>
                            </tr>
                            <tr v-else v-for="item in list" :key="item.id">
                              <td>{{ item?.period_year }}</td>
                              <td>
                                {{
                                  $helperService.convertUnderscore(item?.period)
                                }}
                              </td>
                              <td class="green-text text-right">
                                {{
                                  $helperService.getFormattedCurrency(
                                    item.amount
                                  )
                                }}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0)"
                                  class=""
                                  title="Edit Target"
                                  @click="onShowTargetModal(item)"
                                >
                                  <img src="/static/img/edit-icon.svg" alt="" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ErrorComponent
                          @retry="changeTab(active_tab)"
                          ref="TARGET_ERROR_COMPONENT"
                        />
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-5 text-center">
                          <div class="page-bottom-pagination text-right">
                            <Pagination
                              @page-change="pageChange"
                              @items-per-page-change="itemsPerPageChange"
                              ref="TARGET_PAGINATION"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tab 4 -->
                <div
                  id="tab_4"
                  role="tabpanel"
                  aria-labelledby="tab-4"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-2 mb-2">
                          <VueDatePicker
                            placeholder="Select Date Range"
                            :format="'dd/MM/yyyy'"
                            style="height: 46px !important"
                            v-model="statement_date"
                            range
                            :type="boolean"
                            :default="true"
                            :enable-time-picker="false"
                          >
                          </VueDatePicker>
                        </div>
                        <div class="col-lg-2 mb-2">
                          <VueDatePicker
                            placeholder="Select Payment Date Range"
                            :format="'dd/MM/yyyy'"
                            style="height: 46px !important"
                            v-model="payment_date"
                            range
                            :type="boolean"
                            :default="true"
                            :enable-time-picker="false"
                          >
                          </VueDatePicker>
                        </div>
                        <div class="col-lg-3 mb-2">
                          <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                            >Search
                          </a>
                          <a @click="reset()" class="btn btn-brand-03 mr-2"
                            >Reset
                          </a>
                        </div>
                        <div class="col-lg-5 mb-2 text-right">
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportReport()"
                            class="btn btn-brand-01 mr-2"
                            id="cibil_report"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export
                          </button>
                          &nbsp;&nbsp;
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportSummaryReport()"
                            class="btn btn-brand-01 mr-2"
                            id="summary_report"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Summary Export
                          </button>
                          &nbsp;&nbsp;
                          <a
                            href="javascript:void(0)"
                            @click="$router.push('/add-expense')"
                            class="btn btn-brand-02"
                          >
                            + Add Expenses
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              width="15%"
                              class="sorting"
                              @click="
                                sorting($event, 'fund_transactions.created_at')
                              "
                            >
                              Created Date
                            </th>
                            <th
                              scope="col"
                              width="15%"
                              class="sorting"
                              @click="
                                sorting(
                                  $event,
                                  'fund_transactions.payment_date'
                                )
                              "
                            >
                              Payment Date
                            </th>
                            <th
                              scope="col"
                              width="15%"
                              class="sorting"
                              @click="sorting($event, 'fund_transactions.type')"
                            >
                              Type
                            </th>
                            <th scope="col" width="25%">Description</th>
                            <th scope="col" width="30%">Narration</th>
                            <th
                              scope="col"
                              width="15%"
                              class="sorting text-center"
                              @click="
                                sorting($event, 'fund_transactions.amount')
                              "
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="loader">
                            <td colspan="6" class="text-center">
                              <div
                                class="spinner-border spinner-color"
                                role="status"
                              ></div>
                            </td>
                          </tr>
                          <tr v-else v-for="item in list" :key="item.id">
                            <td>
                              {{
                                $helperService.getFormattedDate(item.created_at)
                              }}
                            </td>
                            <td>
                              {{
                                $helperService.getFormattedDateOnly(
                                  item.payment_date
                                )
                              }}
                            </td>
                            <td>
                              {{ $helperService.getTitleCase(item.type) }}
                            </td>
                            <td>{{ item?.narration }}</td>
                            <td>{{ item?.assoc_narration }}</td>
                            <td
                              class="green-text text-right"
                              v-if="item.trans_type == 'CREDIT'"
                            >
                              {{
                                $helperService.getFormattedCurrency(item.amount)
                              }}
                            </td>
                            <td
                              class="red-text text-right"
                              v-if="item.trans_type == 'DEBIT'"
                            >
                              {{
                                "(" +
                                $helperService.getFormattedCurrency(
                                  item.amount
                                ) +
                                ")"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ErrorComponent
                        @retry="changeTab(active_tab)"
                        ref="STATEMENT_ERROR_COMPONENT"
                      />
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mt-5 text-center">
                        <div class="page-bottom-pagination text-right">
                          <Pagination
                            @page-change="pageChange"
                            @items-per-page-change="itemsPerPageChange"
                            ref="STATEMENT_PAGINATION"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tab 5 -->
                <div
                  id="tab_5"
                  role="tabpanel"
                  aria-labelledby="tab-5"
                  class="tab-pane fade"
                >
                  <div class="card rounded-5">
                    <div class="card-body p-3 p-lg-3">
                      <div class="row">
                        <div class="col-lg-2 mb-2">
                          <VueDatePicker
                            placeholder="Select Month and Year"
                            :format="'MM/yyyy'"
                            style="height: 46px !important"
                            v-model="passbook_date"
                            month-picker
                            :default="true"
                            :enable-time-picker="false"
                          >
                          </VueDatePicker>
                        </div>
                        <div class="col-lg-3 mb-2">
                          <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                            >Search
                          </a>
                          <a @click="reset()" class="btn btn-brand-03 mr-2"
                            >Reset
                          </a>
                        </div>
                        <div class="col-lg-7 mb-2 text-right">
                          <button
                            v-if="access.can_print == 1"
                            @click="onExportPassbookReport()"
                            class="btn btn-brand-01 mr-2"
                            id="branch_passbook_report"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export
                          </button>
                        </div>
                        <div class="table-responsive mx-3">
                          <table class="table table-primary mg-b-0">
                            <thead>
                              <tr>
                                <th scope="col" width="15%" class="sorting">
                                  Record Date
                                </th>
                                <th scope="col" width="15%" class="sorting">
                                  Opening Balance
                                </th>
                                <th scope="col" width="15%" class="sorting">
                                  Closing Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loader">
                                <td colspan="3" class="text-center">
                                  <div
                                    class="spinner-border spinner-color"
                                    role="status"
                                  ></div>
                                </td>
                              </tr>
                              <tr v-else v-for="item in list" :key="item.id">
                                <td>
                                  {{
                                    $helperService.getFormattedDateOnly(
                                      item.record_date
                                    )
                                  }}
                                </td>
                                <td>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      item.opening_amount
                                    )
                                  }}
                                </td>
                                <td>
                                  {{
                                    $helperService.getFormattedCurrency(
                                      item.closing_amount
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <ErrorComponent
                            @retry="changeTab(active_tab)"
                            ref="PASSBOOK_ERROR_COMPONENT"
                          />
                        </div>
                        <div class="row">
                          <div class="col-lg-12 mt-5 text-center">
                            <div class="page-bottom-pagination text-right">
                              <Pagination
                                @page-change="pageChange"
                                @items-per-page-change="itemsPerPageChange"
                                ref="PASSBOOK_PAGINATION"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="viewMoneyInMoneyOutFunds"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="moneyType == 'MONEY_IN'">
              Payment Receive
            </h5>
            <h5 class="modal-title" v-if="moneyType == 'MONEY_OUT'">Payout</h5>
            <h5 class="modal-title" v-if="moneyType == 'FUNDS'">Add Fund</h5>
            <button
              type="button"
              class="close"
              @click="oncloseMoneyInOut()"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <Form
                  @submit="onUpdateMoneyInOut"
                  class="columns column"
                  data-vv-scope="ProfileSave"
                  ref="moneyInOutUpdate"
                >
                  <div class="row">
                    <div class="col-sm-12 mb-3">
                      <label>
                        Amount
                        <span class="text-danger">*</span></label
                      >
                      <Field
                        name="amount"
                        id="amount"
                        rules="required:amount|numeric"
                        step=".01"
                        class="form-control"
                        v-model="moneyObj.amount"
                        type="number"
                        v-bind:placeholder="'Amount'"
                      ></Field>
                      <ErrorMessage name="amount" class="validation-msg" />
                    </div>
                    <!-- <div class="col-sm-12 mb-3" v-if="moneyType != 'FUNDS'">
                                            <label>
                                                Added By
                                                <span class="text-danger">*</span></label>
                                            <Field name="added_by" id="added_by" rules="required:added by"
                                                class="form-control" v-model="moneyObj.added_by" type="text"
                                                v-bind:placeholder="'Added By'"></Field>
                                            <ErrorMessage name="added_by" class="validation-msg" />
                                        </div> -->
                    <div class="col-sm-12 mb-3">
                      <label>
                        Banks
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <!-- <Field v-slot="{ field }" name="funded_by" :validateOnInput="true"
                                                v-model="moneyObj.funded_by">
                                                <Multiselect v-bind="field" ref="partner_multiselect" mode="single"
                                                    trackBy="name" label="name" valueProp="id" placeholder="Select Partner"
                                                    class="form-control" v-model="moneyObj.funded_by" :options="partnerList"
                                                    :searchable="true" />
                                            </Field> -->
                      <Field
                        name="funded_by"
                        id="funded_by"
                        class="form-control"
                        v-model="moneyObj.funded_by"
                        type="text"
                        v-bind:placeholder="'Enter Bank'"
                      ></Field>
                      <ErrorMessage name="funded_by" class="validation-msg" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label> Description </label>
                      <Field
                        v-slot="{ field }"
                        name="description"
                        v-model="moneyObj.narration"
                        type="text"
                        autocapitalize="false"
                      >
                        <textarea
                          v-bind="field"
                          placeholder="Description(Optional)"
                          cols="5"
                          rows="5"
                          class="form-control"
                          v-model="moneyObj.narration"
                        ></textarea>
                      </Field>
                      <ErrorMessage name="description" class="validation-msg" />
                    </div>

                    <div class="col-sm-12 mb-3">
                      <label
                        >Payment Date
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="date"
                        class="form-control"
                        v-slot="{ date }"
                        rules="required:payment date,true"
                        id="date"
                        v-model="moneyObj.payment_date"
                      >
                        <VueDatePicker
                          v-bind="date"
                          placeholder="Select Payment Date "
                          style="height: 46px !important"
                          v-model="moneyObj.payment_date"
                          :type="boolean"
                          :format="'yyyy/MM/dd'"
                          :default="true"
                          :enable-time-picker="false"
                        >
                        </VueDatePicker>
                      </Field>
                      <ErrorMessage name="date" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-info wb-save-btn mt-5 mb-5"
                      id="save-btn"
                    >
                      Submit
                    </button>
                    <!-- <button type="button" class="btn btn-outline-secondary">Cancel</button> -->
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addEditTargetModal"
      tabindex="-1"
      aria-labelledby="addEditTargetModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="target_uuid == null">Add Target</h5>
            <h5 class="modal-title" v-else>Edit Target</h5>
            <button
              type="button"
              class="close"
              @click="onCloseAddTarget()"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <Form
                  @submit="onSaveTarget"
                  class="columns column"
                  data-vv-scope="ProfileSave"
                  ref="onAddEditTargetForm"
                >
                  <div class="row">
                    <div class="col-sm-12 mb-3">
                      <label>
                        Year
                        <span class="text-danger">*</span></label
                      >
                      <Field
                        v-slot="{ feild }"
                        v-model="period_year"
                        name="period_year"
                      >
                        <VueDatePicker
                          placeholder="Select Year"
                          :format="'yyyy'"
                          style="height: 46px !important"
                          v-bind="feild"
                          name="period_year"
                          year-picker
                          rules="required:date"
                          v-model="period_year"
                          :enable-time-picker="false"
                        >
                        </VueDatePicker>
                      </Field>
                      <ErrorMessage name="period_year" class="validation-msg" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label> Quarter</label>

                      <Field
                        v-slot="{ feild }"
                        name="period"
                        v-model="target.period"
                      >
                        <select
                          class="form-control"
                          v-bind="feild"
                          v-model="target.period"
                        >
                          <option value="JAN_MAR">JAN - MAR</option>
                          <option value="APR_JUN">APR - JUN</option>
                          <option value="JUL_SEP">JUL - SEP</option>
                          <option value="OCT_DEC">OCT - DEC</option>
                        </select>
                      </Field>
                      <ErrorMessage name="period" class="validation-msg" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label>
                        Amount
                        <span class="text-danger">*</span></label
                      >
                      <Field
                        name="amount"
                        id="amount"
                        rules="required:amount|numeric"
                        step=".01"
                        class="form-control"
                        v-model="target.amount"
                        type="number"
                        v-bind:placeholder="'Amount'"
                      ></Field>
                      <ErrorMessage name="amount" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-info wb-save-btn mt-5 mb-5"
                      id="target-btn"
                    >
                      Submit
                    </button>
                    <!-- <button type="button" class="btn btn-outline-secondary">Cancel</button> -->
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal @remove="onDeleteFund" ref="deleteFund" />
</template>
<script>
import moment from "moment";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css"
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  name: "ManageEditBranch",
  components: {
    Pagination,
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    ConfirmationModal,
    // Multiselect
  },
  computed: {
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  data() {
    return {
      id: this.$route.params.uuid,
      permission: this.$route.params.access,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        type: "CREDIT",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
      },
      period_year: ref(new Date().getFullYear()),
      moneyObj: {
        amount: "",
        added_by: "",
        payment_date: "",
        narration: "",
      },
      partnerList: [],
      delete_id: "",
      passbook_date: ref({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      }),
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      moneyOutTypes: [
        {
          title: "TARGET",
          value: "TARGET",
        },
        {
          title: "FUNDS",
          value: "FUNDS",
        },
        {
          title: "Incentive",
          value: "INCENTIVE",
        },
        {
          title: "Lend",
          value: "LEND",
        },
        // {
        //   title: "Advance Payment",
        //   value: "ADVANCE_PAYMENT",
        // },
        {
          title: "Other",
          value: "OTHER",
        },
      ],
      moneyInTypes: [
        {
          title: "Borrow",
          value: "BORROW",
        },
        {
          title: "Investment",
          value: "INVESTMENT",
        },
        {
          title: "Other",
          value: "OTHER",
        },
      ],

      active_tab: "SUMMARY",
      loader: false,
      userSummary: "",
      moneyType: "",
      target: {
        period: "JAN_MAR",
        period_year: "",
        amount: "",
      },
      target_uuid: null,
      fund_statement_date: null,
      payment_date: null,
      fund_payment_date: null,
    };
  },
  mounted() {
    this.active_tab = localStorage.getItem("active_tab")
      ? localStorage.getItem("active_tab")
      : "SUMMARY";
    this.changeTab(this.active_tab);
    this.onGetPartnerList();
  },
  methods: {
    onDeleteFundEvent(item) {
      this.delete_id = item?.uuid;
      this.$refs.deleteFund.showModal(
        "Confirmation",
        "Are you sure you want to delete?"
      );
    },

    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    onUpdateMoneyInOut() {
      if (this.moneyType == "MONEY_IN") {
        this.moneyObj.type = "CREDIT";
      }
      if (this.moneyType == "MONEY_OUT") {
        this.moneyObj.type = "DEBIT";
      }
      if (this.moneyType == "FUNDS") {
        this.moneyObj.type = "CREDIT";
      }
      this.moneyObj.payment_date = moment(this.moneyObj.payment_date).format(
        "Y-MM-DD"
      );
      this.$api
        .webRequest({
          _method: "POST",
          _action: "branch/" + this.id + "/ledger",
          _buttonId: "save-btn",
          _body: this.moneyObj,
        })
        .then((res) => {
          this.onClearForm("moneyInOutUpdate");
          this.changeTab(this.active_tab);
          this.oncloseMoneyInOut();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onSaveTarget() {
      var method = "";
      var url = "";
      if (this.target_uuid) {
        method = "PUT";
        url = "branch/" + this.id + "/fund/" + this.target_uuid;
      } else {
        method = "POST";
        url = "branch/" + this.id + "/fund";
      }

      this.target.period_year = this.period_year;
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _buttonId: "target-btn",
          _body: this.target,
        })
        .then((res) => {
          this.onClearForm("onAddEditTargetForm");
          this.changeTab(this.active_tab);
          this.onCloseAddTarget();
          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onMoneyInOut(type) {
      window.$("#viewMoneyInMoneyOutFunds").modal("show");
      this.moneyType = type;
    },
    oncloseMoneyInOut() {
      window.$("#viewMoneyInMoneyOutFunds").modal("hide");
      this.moneyType = null;
      this.moneyObj = {};
    },
    onShowTargetModal(target_uuid) {
      if (target_uuid != null) {
        this.target_uuid = target_uuid.uuid;
        this.period_year = ref(new Date(target_uuid.period_year).getFullYear());
        this.target.period = target_uuid.period;
        this.target.amount = target_uuid.amount;
      }
      window.$("#addEditTargetModal").modal("show");
    },
    onCloseAddTarget() {
      this.target_uuid = null;
      window.$("#addEditTargetModal").modal("hide");
      // this.target = {};
      this.target = {
        period: "JAN_MAR",
        period_year: ref(new Date().getFullYear()),
        amount: "",
      };
    },
    changeTab(tab) {
      this.active_tab = tab;
      localStorage.setItem("active_tab", tab);
      if (tab == "SUMMARY") {
        this.getUserSummary();
      } else if (tab == "FUNDS") {
        window.$("#tab-2").click();
        this.getList(1);
      } else if (tab == "TARGET") {
        window.$("#tab-3").click();
        this.getList(1);
      } else if (tab == "STATEMENT") {
        window.$("#tab-4").click();
        this.getList(1);
      } else if (tab == "PASSBOOK") {
        window.$("#tab-5").click();
        this.getList(1);
      } else {
        window.$("#tab-1").click();
        this.getList(1);
      }
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
      };
      
    },
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "",
      };
      this.passbook_date = null;
      this.statement_date = null;
      this.payment_date = null;
      this.getList(1);
    },
    view(item) {
      this.$router.push("/view-employee-FUNDS/" + item.uuid);
    },
    addOfficeFUNDS() {
      localStorage.setItem("FUNDS_status", "EMPLOYEE");
      this.$router.push("/add-FUNDS");
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      var tab = this.active_tab;

      if (tab == "FUNDS") {
        this.itemsPerPage = this.$refs.FUNDS_PAGINATION.itemsPerPage;
      }
      if (tab == "TARGET") {
        this.itemsPerPage = this.$refs.TARGET_PAGINATION.itemsPerPage;
      }

      this.getList(1);
    },
    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              false
            );
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
          if (this.$refs[errorCompRef]) {
            this.$refs[errorCompRef].setTitle(
              "Sorry, There is no record found.",
              true
            );
          }
        }
      }
      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }
      // var checkValue = false;
      // if (listLength === 0) {
      //     checkValue = true;
      // }
      // if (checkValue) {
      //     if (this.$refs[errorCompRef]) {
      //         this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
      //     }
      // }
    },
    retry() {
      this.getList(1);
    },

    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "branch/" + this.id + "/statements/export",
          _body: this.filterObj,
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onExportPassbookReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "branch-passbook/" + this.id + "/download",
          _body: this.filterObj,
          _buttonId: "branch_passbook_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onExportSummaryReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "branch/" + this.id + "/summary/export",
          _body: this.filterObj,
          _buttonId: "summary_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },

    getList(page, sortBy, orderBy) {
      this.filterObj.type = this.active_tab;
      this.filterObj.offset = page - 1;
      this.filterObj.type = "ALL";
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.list = [];

      this.loader = true;

      var method = "GET";

      var tab = this.active_tab;

      var url;

      if (tab == "FUNDS") {
        url = "branch/" + this.id + "/ledgers";
        
        if (this.fund_statement_date) {
          this.filterObj.from_date = moment(
            String(this.fund_statement_date[0])
          ).format("Y-MM-DD");
          this.filterObj.to_date = moment(
            String(this.fund_statement_date[1])
          ).format("Y-MM-DD");
        } else {
          this.filterObj.from_date = "";
          this.filterObj.to_date = "";
        }
        if (this.fund_payment_date) {
          this.filterObj.from_payment_date = moment(
            String(this.fund_payment_date[0])
          ).format("Y-MM-DD");
          this.filterObj.to_payment_date = moment(
            String(this.fund_payment_date[1])
          ).format("Y-MM-DD");
        } else {
          this.filterObj.from_payment_date = "";
          this.filterObj.to_payment_date = "";
        }
      }
      if (tab == "TARGET") {
        url = "branch/" + this.id + "/funds";
      }
      if (tab == "STATEMENT") {
        url = "branch/" + this.id + "/statements";
        if (this.statement_date) {
          this.filterObj.from_date = moment(
            String(this.statement_date[0])
          ).format("Y-MM-DD");
          this.filterObj.to_date = moment(
            String(this.statement_date[1])
          ).format("Y-MM-DD");
        } else {
          this.filterObj.from_date = "";
          this.filterObj.to_date = "";
        }
        if (this.payment_date) {
          this.filterObj.from_payment_date = moment(
            String(this.payment_date[0])
          ).format("Y-MM-DD");
          this.filterObj.to_payment_date = moment(
            String(this.payment_date[1])
          ).format("Y-MM-DD");
        } else {
          this.filterObj.from_payment_date = "";
          this.filterObj.to_payment_date = "";
        }
      }
      if (tab == "PASSBOOK") {
        url = "branch-passbook/" + this.id;
        if (this.passbook_date) {
          
          let monthVal = this.passbook_date?.month + 1;

          let finalDate = null;
          if (monthVal <= 9) {
            finalDate = this.passbook_date?.year + "-0" + monthVal;
          } else {
            finalDate = this.passbook_date?.year + "-" + monthVal;
          }
          console.log(finalDate, "finalDate");
          this.filterObj.period = finalDate;
        }
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.access = res.access;
          var tab = this.active_tab;
          if (tab == "FUNDS") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "FUNDS_PAGINATION",
              "FUNDS_ERROR_COMPONENT"
            );
          } else if (tab == "TARGET") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "TARGET_PAGINATION",
              "TARGET_ERROR_COMPONENT"
            );
          } else if (tab == "STATEMENT") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "STATEMENT_PAGINATION",
              "STATEMENT_ERROR_COMPONENT"
            );
          } else if (tab == "PASSBOOK") {
            // this.passbook_date = null;
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "PASSBOOK_PAGINATION",
              "PASSBOOK_ERROR_COMPONENT"
            );
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });

        this.filterObj.sort_by = "created_at";
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },
    getUserSummary() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "branch/" + this.id,
        })
        .then((res) => {
          this.userSummary = res.info;
          this.onGetPartnerList(res?.info?.id);
          this.$storeService.commit("setTitle", res?.info?.name);
          this.userSummary.total_available_fund =
            res?.info?.total_available_fund;
          var branches = res?.info?.branches;
          if (branches.length > 0) {
            var x_branch_id = localStorage.getItem("branch_id");
            branches.forEach((obj) => {
              if (obj.uuid == x_branch_id) {
                this.userSummary.city = obj.city;
              }
            });
          }
        })
        .catch(() => {});
    },
    onGetPartnerList(id) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/partners",
          _body: { branch_id: id },
        })
        .then((res) => {
          this.partnerList = res.list;
        })
        .catch(() => {});
    },

    onDeleteFund() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "branch/" + this.id + "/ledger/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteFund.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteRole.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
  },
};
</script>
